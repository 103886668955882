import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { TextBold } from './TextBold';
import Sub1 from '../../images/ais-images/Sub1.png';
import Sub2 from '../../images/ais-images/Sub2.png';
import Sub3 from '../../images/ais-images/Sub3.png';
import Sub4 from '../../images/ais-images/Sub4.png';
import CONSTANTS from '../../utils/Constants';
import { savePayload } from '../../actions/Dashboard/doFindAgreements';
import { CLEAR_AGREEMENT, SELECTED_ASSET_PROGRAM_NAME } from '../../actions/Dashboard/actionTypes';
import {
  getServiceFee,
  setSeletctedAsset,
  verifyAgreement,
  getServiceFeePromise,
  getAllSRSuccess,
  getAnySRFailed,
  deviceListLoadingProgress,
} from '../../actions/Dashboard/doFindAgreements';
import {
  agreementStatus,
  BaseImg,
  getDeviceName,
  getFormattedUserName,
  getMdnWithoutUnderscore,
} from '../../utils/utils';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import Carousel from 'react-bootstrap/Carousel';
import { chain, indexOf } from 'lodash';
import { find } from 'lodash';

const { SUBSCRIPTION_OPTION } = MESSAGE_CONSTANTS;
class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      yesButtonClassName: 'ais_align-popup-button',
      noButtonClassName: 'ais_align-popup-button',
      deviceName: '',
      deviceSpec: '',
      /**
       * Keeping state locally because when rendering same component again,
       * redux state will affect css for proceed button
       */
      selectedAsset: null,
      index: 0,
      isMobile: window.innerWidth < 768,
    };
  }

  updateSelectedAsset = (asset) => {
    this.setState({ selectedAsset: asset });
    this.props.setSeletctedAsset(asset);
  };

  // static filterAllAgreementAssets = (agreements = []) => {
  //   let uniqueDevices = [];
  //   const priority = ['ENROLLED', 'REPLACED'];
  //   agreements.forEach((agreement) => {
  //     const asset = chain(agreement.AssetsList.Asset)
  //       .filter((asset) => priority.includes(asset.AssetInstance))
  //       .filter((asset) =>
  //         asset.AssetInstance === 'ENROLLED' && asset.EndDate
  //           ? new Date(asset.EndDate).getTime() > new Date().getTime()
  //           : true
  //       )
  //       .orderBy(['StartDate', (asset) => indexOf(priority, asset.AssetInstance)], ['desc', 'asc'])
  //       .thru((assets) =>
  //         assets.length > 0
  //           ? assets
  //           : [find(agreement.AssetsList.Asset, ['AssetInstance', priority[0]])]
  //       )
  //       .value()
  //       .shift();
  //     uniqueDevices.push({ ...asset, agreement });
  //   });

  //   return uniqueDevices.sort((a, b) => a.IMEI - b.IMEI);
  // };

  static filterAllAgreementAssets = (agreements = []) => {
    let uniqueDevices = [];
    
    agreements.forEach(agreement => {
      let enrolledAssets = agreement.AssetsList.Asset.filter((value) => value.AssetInstance === 'ENROLLED');
      let currentAsset = enrolledAssets[0]; // currentAsset is enrolledAsset by default
      let replacedAssets = agreement.AssetsList.Asset.filter((value) => value.AssetInstance === 'REPLACED');

      let sortedReplacedAssetsByCreateDate = replacedAssets && replacedAssets.length
        ? replacedAssets.sort((a, b) => moment(b.CreatedDate).format('YYYYMMDD') - moment(a.CreatedDate).format('YYYYMMDD'))
        : null;

      let enrolledAssetDate = currentAsset.StartDate
        ? moment(currentAsset.CreatedDate).isAfter(moment(currentAsset.StartDate))
          ? currentAsset.CreatedDate
          : currentAsset.StartDate
        : currentAsset.CreatedDate

      let replacedAssetDate = sortedReplacedAssetsByCreateDate && sortedReplacedAssetsByCreateDate.length
        ? sortedReplacedAssetsByCreateDate[0].StartDate
          ? moment(sortedReplacedAssetsByCreateDate[0].CreatedDate).isAfter(moment(sortedReplacedAssetsByCreateDate[0].StartDate))
            ? sortedReplacedAssetsByCreateDate[0].CreatedDate
            : sortedReplacedAssetsByCreateDate[0].StartDate
          : sortedReplacedAssetsByCreateDate[0].CreatedDate
        : ""

      // if (currentAsset.EndDate) {
      //   if (!moment(currentAsset.EndDate).isValid() || moment().isAfter(moment(currentAsset.EndDate))) {
      //     if (sortedReplacedAssetsByCreateDate &&
      //       sortedReplacedAssetsByCreateDate.length &&
      //       sortedReplacedAssetsByCreateDate[0] &&
      //       sortedReplacedAssetsByCreateDate[0].StartDate &&
      //       (moment(sortedReplacedAssetsByCreateDate[0].StartDate).isAfter(moment(currentAsset.StartDate)) ||
      //         moment(sortedReplacedAssetsByCreateDate[0].CreatedDate).isAfter(moment(currentAsset.CreatedDate)))) {
      //       currentAsset = sortedReplacedAssetsByCreateDate[0];
      //     }
      //   }
      // } else {
      //   if (sortedReplacedAssetsByCreateDate) {
      //     if (moment(currentAsset.StartDate).format('YYYYMMDD') !==
      //       moment(sortedReplacedAssetsByCreateDate[0].StartDate).format('YYYYMMDD')) {
      //       if (moment(currentAsset.StartDate).isBefore(moment(sortedReplacedAssetsByCreateDate[0].StartDate))) {
      //         currentAsset = (sortedReplacedAssetsByCreateDate[0]) || currentAsset;
      //       }
      //     }
      //   } else {
      //     let notCusVerifyAssets = agreement.AssetsList.Asset.filter((value) => (value.AssetInstance === 'ENROLLED' || value.AssetInstance === 'REPLACED'));
      //     let sortedNotCusVerifyAssets = notCusVerifyAssets.sort((a, b) => moment(b.CreatedDate).format('YYYYMMDD') - moment(a.CreatedDate).format('YYYYMMDD'))
      //     currentAsset = sortedNotCusVerifyAssets[0];
      //   }
      // }

      // if (currentAsset.AssetInstance === "REPLACED" && sortedReplacedAssetsByCreateDate.length > 1) {
      //   if (moment(currentAsset.StartDate).format('YYYYMMDD') ===
      //     moment(enrolledAssets[0].StartDate).format('YYYYMMDD') &&
      //     moment(sortedReplacedAssetsByCreateDate[1].StartDate).isAfter(moment(enrolledAssets[0].StartDate))) {
      //     currentAsset = sortedReplacedAssetsByCreateDate[1];
      //   }
      // }

      if (currentAsset.EndDate) {
        if (!moment(currentAsset.EndDate).isValid() || moment().isAfter(moment(currentAsset.EndDate))) {
          if (sortedReplacedAssetsByCreateDate &&
            sortedReplacedAssetsByCreateDate.length &&
            sortedReplacedAssetsByCreateDate[0] &&
            replacedAssetDate &&
            moment(replacedAssetDate).isAfter(moment(enrolledAssetDate))) {
            currentAsset = sortedReplacedAssetsByCreateDate[0];
          }
        }
      } else {
        if (sortedReplacedAssetsByCreateDate) {
          if (moment(enrolledAssetDate).format('YYYYMMDD') !==
            moment(replacedAssetDate).format('YYYYMMDD')) {
            if (moment(enrolledAssetDate).isBefore(moment(replacedAssetDate))) {
              currentAsset = (sortedReplacedAssetsByCreateDate[0]) || currentAsset;
            }
          }
        } else {
          let notCusVerifyAssets = agreement.AssetsList.Asset.filter((value) => (value.AssetInstance === 'ENROLLED' || value.AssetInstance === 'REPLACED'));
          let sortedNotCusVerifyAssets = notCusVerifyAssets.sort((a, b) => moment(b.CreatedDate).format('YYYYMMDD') - moment(a.CreatedDate).format('YYYYMMDD'))
          currentAsset = sortedNotCusVerifyAssets[0];
        }
      }

      if (currentAsset.AssetInstance === "REPLACED" && sortedReplacedAssetsByCreateDate.length > 1) {
        replacedAssetDate = sortedReplacedAssetsByCreateDate && sortedReplacedAssetsByCreateDate.length
        ? sortedReplacedAssetsByCreateDate[1].StartDate
          ? moment(sortedReplacedAssetsByCreateDate[1].CreatedDate).isAfter(moment(sortedReplacedAssetsByCreateDate[1].StartDate))
            ? sortedReplacedAssetsByCreateDate[1].CreatedDate
            : sortedReplacedAssetsByCreateDate[1].StartDate
          : sortedReplacedAssetsByCreateDate[1].CreatedDate
        : ""

        if (moment(currentAsset.StartDate).format('YYYYMMDD') ===
          moment(enrolledAssetDate).format('YYYYMMDD') &&
          moment(replacedAssetDate).isAfter(moment(enrolledAssetDate))) {
          currentAsset = sortedReplacedAssetsByCreateDate[1];
        }
      }

      uniqueDevices.push({ ...currentAsset, agreement })
    });

    return uniqueDevices.sort((a, b) => a.IMEI - b.IMEI);
  }

  async componentDidMount() {
    let uniqueAgreementAssets = DeviceList.filterAllAgreementAssets(this.props.Agreement);
    this.props.savePayload(CLEAR_AGREEMENT);
    this.props.deviceListLoadingProgress();
    const results = uniqueAgreementAssets.map((asset) =>
      getServiceFeePromise(asset.MobileDeviceNumber, this.props.customerData)
    );
    const responses = await Promise.all(results);
    const passed = responses.filter(({ status }) => status === 200);
    const SRs = passed.map(({ data }) => data.GetServiceFeeResponse);
    this.props.getAllSRSuccess(SRs, this.props.customerData);
  }
  handleClick = () => {
    this.setState({ disable: true });
    this.props.updateJourneyList(
      CONSTANTS.SOURCE_USER_INPUT.RESUME_REQUEST_OPTIONS,
      MESSAGE_CONSTANTS.BACK_TO_MAIN_MENU
    );
  };
  handleNoClick = () => {
    this.setState({
      disable: true,
      noButtonClassName: 'ais_align-popup-button-selected',
    });
    this.props.updateJourneyList(
      CONSTANTS.UPDATE_ENROLLED_DEVICE,
      MESSAGE_CONSTANTS.UPDATE_ENROLLED_DEVICE
    );
  };
  handleYesClick = async (AgreementId, asset, deviceName, SubscriptionDetails) => {
    let { cacheId, updateJourneyList, selectedDefectOption, caseType } = this.props;
    this.updateSelectedAsset(asset);
    let selectedDeviceMsg = MESSAGE_CONSTANTS.PROCEED_SELECTED_DEVICE.replace(
      '{selectedDevice}',
      deviceName || 'Unknown Device'
    );
    if (selectedDefectOption === CONSTANTS.LEAVE_AIS) {
      selectedDefectOption = MESSAGE_CONSTANTS.SELECTED_DEVICE.replace(
        '{selectedDevice}',
        deviceName || 'Unknown Device'
      );
      updateJourneyList(CONSTANTS.LEAVE_AIS, selectedDeviceMsg);
    } else {
      updateJourneyList(null, selectedDeviceMsg);
    }

    let ProcessIncidentParameters = {
      SessionId: cacheId,
      Incident: {
        FailureDescriptiveText: selectedDefectOption,
        IncidentType: caseType,
      },
    };
    let VerifyAgreementsParameters = {
      SessionId: cacheId,
      AgreementId,
    };
    asset = Object.assign({}, asset, {
      programName: SubscriptionDetails.ProgramName,
    });
    this.props.savePayload(SELECTED_ASSET_PROGRAM_NAME, asset);
    await this.props.verifyAgreement(VerifyAgreementsParameters, ProcessIncidentParameters);
    this.setState({
      disable: true,
      yesButtonClassName: 'ais_align-popup-button-selected',
    });
  };
  Slide = ({ agreement, asset, selectedAsset }) => {
    const { serviceFeeResponse } = this.props;
    // Getting service fee for given asset
    const serviceFeeDetails = serviceFeeResponse.find(
      (sf) => sf.SubscriptionDetails.MobileDeviceNumber === asset.MobileDeviceNumber
    );
    const deviceName = getDeviceName({
      AssetsInformation: asset.AssetsInformation,
      MakeName: asset.Make.Name,
      ModelName: asset.Model.Name,
    });
    let { selectedDefectOption } = this.props;

    const showProceedBtn =
      selectedDefectOption === 'SUBSCRIPTION' ||
      selectedDefectOption === CONSTANTS.CHECK_SERVICE_FEE;
    let SubscriptionDetails = serviceFeeDetails?.SubscriptionDetails;
    let validation = SubscriptionDetails && SubscriptionDetails.ProgramName ? true : false;
    let ServiceFee = serviceFeeDetails?.ServiceFee;
    let swapFee = serviceFeeDetails?.swapFee;
    let replacementFee = serviceFeeDetails?.replacementFee;

    let TotalAmount = swapFee && swapFee.Fees && swapFee.Fees.TotalAmount;
    let TotalAmountRep = swapFee && swapFee.Fees && replacementFee.Fees.TotalAmount;
    let TotalAmountRepCurrency = 'THB';
    if (!isNaN(Number(TotalAmountRep)) && Number(TotalAmountRep) === 0) {
      TotalAmountRep = '-';
      TotalAmountRepCurrency = '';
    }
    const name = getFormattedUserName(agreement?.EnrolledName || 'User');
    const IMEI = asset.IMEI.replace(/\d(?=\d{3})/g, 'X');

    const isSelectedAsset = selectedAsset && selectedAsset.AssetId === asset.AssetId;
    const selectedAssetClass = isSelectedAsset ? 'selected-asset' : 'non-selected-asset';
    const assetClass = selectedAsset ? selectedAssetClass : '';

    const SUBSCRIPTION_OPTIONS = [
      {
        icon: Sub1,
        label: SUBSCRIPTION_OPTION.Sub1,
        value: !name.lastName ? name.firstName : name.fullName,
      },
      {
        icon: Sub2,
        label: SUBSCRIPTION_OPTION.Sub5,
        value: `<span class= "SubscriptionFeecss">${IMEI}</span>`,
      },
      {
        icon: Sub3,
        label: SUBSCRIPTION_OPTION.Sub2,
        value: `<span class= "SubscriptionFeecss">THB ${
          ServiceFee || MESSAGE_CONSTANTS.NO_DATA_AVAILABLE
        } ${this.props.IsMonthly ? '/month' : '/year</span>'}`,
      },
      {
        icon: Sub3,
        label: SUBSCRIPTION_OPTION.Sub3,
        value: `<div>Swap <span class= "SubscriptionFeecss">THB ${
          TotalAmount || MESSAGE_CONSTANTS.NO_DATA_AVAILABLE
        }</span></div><div class="subscriptionItemDesc">Replacement <span class="SubscriptionFeecss">${TotalAmountRepCurrency} ${
          TotalAmountRep || MESSAGE_CONSTANTS.NO_DATA_AVAILABLE
        } <span></div>`,
      },
      {
        icon: Sub4,
        label: SUBSCRIPTION_OPTION.Sub4,
        value: moment(agreement && agreement.AgreementStartDate).format('DD MMMM YYYY'),
        status: agreementStatus(agreement && agreement.AgreementStatus),
      },
    ];
    return (
      <article className="devicelist-slide item ">
        <div className="secSlider">
          <div className="flex items-center justify-center pb-4">
            <div className="deviceIcon">
              <BaseImg make={asset?.Make?.Name} model={asset?.Model?.Name} />
            </div>
            <div className="flex flex-col pl-3">
              <label className="subLabelnewData">{deviceName || 'Device Unknown'}</label>
              <TextBold className="text-sm subLabelnewData">
                {getMdnWithoutUnderscore(asset.MobileDeviceNumber)}
              </TextBold>
              <p className="subLabelnew">{SubscriptionDetails?.ProgramName}</p>
            </div>
          </div>
          <div className="wrapper">
            <div className="subscriptionLabel subLabelnewData">{SUBSCRIPTION_OPTION.TITLE}</div>
            <div className="subscriptionItemsWrapper">
              {SUBSCRIPTION_OPTIONS &&
                SUBSCRIPTION_OPTIONS.map((e, i) => (
                  <div className="flex m-15 subscriptionItems" key={e.label}>
                    <div className="imageDist">
                      <img src={e && e.icon} />
                    </div>
                    <div className="text-initial">
                      <div className="subLabelnew">{e && e.label}</div>
                      <div className="subLabelnewData subscriptionItemDesc">
                        <span dangerouslySetInnerHTML={{ __html: e && e.value }}></span>
                      </div>
                      {e.status && <button className="btnSub btn-darkgreenSub ">{e.status}</button>}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {!showProceedBtn && (
            <div className="proceed-btn">
              <button
                disabled={this.state.disable || !validation}
                className={`${this.state.yesButtonClassName} ${assetClass}`}
                onClick={(e) =>
                  this.handleYesClick(agreement.AgreementId, asset, deviceName, SubscriptionDetails)
                }
              >
                {SUBSCRIPTION_OPTION.Proceed}
              </button>
            </div>
          )}
        </div>
      </article>
    );
  };

  render() {
    let uniqueAgreementAssets = DeviceList.filterAllAgreementAssets(this.props.Agreement);
    const { ClientOffer } = this.props;
    let { selectedDefectOption } = this.props;
    const flag =
      selectedDefectOption === 'SUBSCRIPTION' ||
      selectedDefectOption === CONSTANTS.CHECK_SERVICE_FEE;
    const disableClass = this.state.disable && 'disable-link';

    const deviceHeightCSS = !flag ? 'device-height-1' : 'device-height-2';
    const items =
      /* serviceFeeResponse && serviceFeeResponse.length === Agreement.length &&  */ uniqueAgreementAssets.map(
        (asset, i) => (
          <Carousel.Item bsPrefix={this.state.isMobile ? 'carousel-item' : 'non-carousel-item'}>
            <this.Slide
              id={asset.AssetId}
              key={asset.AssetId}
              agreement={asset.agreement}
              asset={asset}
              plan={ClientOffer}
              updateSelectedAsset={this.updateSelectedAsset}
              selectedAsset={this.state.selectedAsset}
            ></this.Slide>
          </Carousel.Item>
        )
      );

    if (this.props.loading && !this.state.disable) {
      return null;
    }

    const showUpdateEnrollDeviceLink =
      selectedDefectOption !== CONSTANTS.CHECK_SERVICE_FEE ||
      selectedDefectOption !== CONSTANTS.LEAVE_AIS;

    return (
      <section className="max-w-5xl mx-auto disable">
        <p className="section-title">{SUBSCRIPTION_OPTION.SectionTitle}</p>
        <h3 className="enrolled-devices">
          {SUBSCRIPTION_OPTION.EnroledDevice}&nbsp;
          <span className="font-AvenirLTStd-Book font-normal">
            ({uniqueAgreementAssets.length})
          </span>
        </h3>
        <div className={`container horizontal-slider ${deviceHeightCSS}`}>
          {this.state.isMobile ? (
            <>
              <Carousel
                activeIndex={this.state.index}
                onSelect={(selectedIndex) => this.setState({ index: selectedIndex })}
                interval={null}
              >
                {items}
              </Carousel>
              <div className="subLabelnewData swipe-devices">
                {SUBSCRIPTION_OPTION.SwipeToSeeAllDevices}
              </div>
            </>
          ) : (
            <div className="non-carousel-wrapper">{items}</div>
          )}
        </div>
        {showUpdateEnrollDeviceLink && (
          <div className={`update-device ${this.state.disable && disableClass}`}>
            <div className=" updateLinkText text-center">{SUBSCRIPTION_OPTION.CantFindDevice} </div>
            <div className={`updateLinkSub text-center `} onClick={(e) => this.handleNoClick(e)}>
              {SUBSCRIPTION_OPTION.ClickToUpdate}
            </div>
          </div>
        )}
        {flag && (
          <div className={`subBackToMainMenu text-center ${this.state.disable && disableClass}`}>
            <span onClick={this.handleClick}>{MESSAGE_CONSTANTS.BACK_TO_MAIN_MENU}</span>
          </div>
        )}
      </section>
    );
  }
}
const mapStateToProps = ({ customerData }) => {
  return {
    Agreement: customerData.Agreement,
    ClientOffer: customerData.ClientOffer,
    serviceFeeResponse: customerData.serviceFeeResponse,
    swapFee: customerData.swapFee,
    replacementFee: customerData.replacementFee,
    IsMonthly: customerData.IsMonthly,
    imageBaseUrl: customerData.clientData.imageBaseUrl,
    subscriptionDeviceImgName: customerData.subscriptionDeviceImgName,
    selectedDefectOption: customerData.selectedDefectOption,
    cacheId: customerData.clientData.CacheId,
    caseType: customerData.caseType,
    customerData,
    loading: customerData.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getServiceFee,
      setSeletctedAsset,
      verifyAgreement,
      savePayload,
      getAllSRSuccess,
      getAnySRFailed,
      deviceListLoadingProgress,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(DeviceList);
